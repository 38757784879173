













import { Component, Prop } from 'vue-property-decorator'

import { LinkWithLabel, PickerCallback, UnresolvedLink } from '../../../../../contracts'
import { ResolvesRelatedAsync } from '../../../../../services'

import { LinkForm } from '../../../../molecules'

import { AbstractAddon } from '../AbstractAddon.form'

import { SearchAddon } from './Search.contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (edited)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 */
@Component<SearchAddonForm>({
  name: 'SearchAddonForm',
  components: { LinkForm }
})
export class SearchAddonForm extends AbstractAddon {
  /**
   * An instance of the `RelatedService`.
   */
  @Prop({ type: Object, required: false })
  public readonly relatedService!: ResolvesRelatedAsync

  /**
   * Callback function for the `<LinkForm>`.
   *
   * @see RelatedPartial.pickRelated
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  public onLinkUpdate (link: LinkWithLabel | UnresolvedLink): void {
    this._item = { ...this._item, searchResultsPage: link } as SearchAddon
  }
}

export default SearchAddonForm
